@import '../../../assets/styles/setting';
body{
.assets-row{
  margin-bottom:5px;
  margin-left: 0;
  margin-right: 0;
  .marginRight{
    margin-right:10px;
  }
  .marginLeft{
    margin-left:10px;
  }
  .right-column{
    cursor: pointer;
     margin-left: 0px;
     margin-right: 0px;
     width: 49%;
     display: inline-block;
     height: 50px!important;
     padding-top: 5px;
  }
  .left-column{
    cursor: pointer;
     margin-left: 0px;
     margin-right: 0px;
     width: 49%;
     display: inline-block;
     margin-right: 2%;
     height: 50px!important;
     padding-top: 5px;
  }
 .widget-cards{
   height: 40px;
   border-radius: 4px;
   justify-content: center;
   box-shadow: 0 2px 4px rgba(0,0,0,0.28);
   background-position: center;
   a{
     width:100%;
     text-align: center;
     &:hover{

     }
     h5{
       font-family: $ibmplexRegular;
       font-size: 19px;
     }
     h6{
       font-family: $ibmplexSemiBold;
       font-size: 12px;
     }
   }
   &.total-count{
     background: linear-gradient(90deg, rgb(44, 69, 193), rgba(150, 95, 222, 0.93));
     a{
       color: #fff;
     }

   }
   &.off-lease{
     background-image: linear-gradient(269deg,#ffdfcb,#ffb98d 95%);
     a{
       color: #ee7c26;
     }

   }
   &.on-lease{
     background-image: linear-gradient(90deg,#beed87,#d2e9b6 95%);
     a{
       color: #5ec264;
     }

   }
   &.on-ground{
     background-image: linear-gradient(269deg,#a7c4ff,#729cf1);

     a{
       color: #4c79d5;
     }

   }
   &.written-off{
     background: linear-gradient(180deg,#fff 0,#f6f6f6 7%,#d7d7d7);

     a{
       color: #333;
     }

   }
 }
}
  .fleet-technical-db{
    margin: 20px 0px 15px 55px;
    padding: 0;
    table{
      width: 100%;
    }
    .technical-manpower-info{
      margin-bottom: 20px;
      h5{
        font-size: 24px;
        font-family: $ibmplexRegular;
        text-transform: uppercase;
        margin-bottom: 3px;
      }
      h6{
        font-size: 14px;
        font-family: $ibmplexSemiBold;
        text-transform: uppercase;
      }
      .manpower-widget{
        text-align: center;
        background-image: linear-gradient(90deg, #3d297d, #5943a0);
        height: 86px;
        border-radius: 4px;
        justify-content: center;
        box-shadow: 0 2px 4px rgba(0,0,0,.28);
        background-position: 50%;
        h5,h6{
          color: $white;
        }
      }
      .work-order{
        background: $white;
        box-shadow: 0 2px 4px rgba(0,0,0,.28);
        border-radius: 4px;
          text-align: center;
          height: 86px;
          h5,h6{
            color: $black;
          }
          img{
            width: 36px;
          }
        }
        table{
          background: $white;
          box-shadow: 0 2px 4px rgba(0,0,0,.28);
          border-radius: 4px;
            text-align: center;
            height: 86px;
            h5,h6{
              color: $black;
            }
            img{
              width: 36px;
            }
          }
    }
    .technical-last-assets{
      margin-bottom: 20px;

      .slick-arrow{
        background: rgba(0,0,0,0.4);
        height: 21px !important;
      }

      .expiry-title{
        font-size: 16px;
        font-family: $ibmplexRegular;
        color: #4a4a4a;
        margin-bottom: 10px;
      }
      .asset-info-card{
        border: 1px solid #cccccc;
        background: $white;
        max-height: 256px;
        overflow-y: auto;
        border-radius: 4px;
        padding: 15px;
        margin-right: 10px;
        .asset-detail{
          clear: both;
          margin-bottom: 10px;
          img{
            float: left;
            width: 62px;
          }
          >div{
            margin-left: 84px;
            h6{
              font-size: 12px;
              font-family: $ibmplexRegular;
              color: #838383;
            }
            h2{
              line-height: 18px;
              a{
                font-size: 18px;
                font-family: $ibmplexSemiBold;
                color: $black;
                line-height: 20px;
              }
            }

            span{
              border: 1px solid #417505;
              border-radius: 23px;
              padding: 0px 6px;
              font-size: 10px;
              font-family: $ibmplexRegular;
              color: #417505;
            }
            p{
              font-size: 12px;
              font-family: $ibmplexRegular;
              color: #838383;
            }
          }
        }
        table{
          clear: both;
          tr{
            &:first-child{
              td{
                text-align: center;
              }
            }
            td{
              padding: 5px;
              h4{
                font-size: 20px;
                font-family: $ibmplexRegular;
                color: #354052;
              }
              h6{
                font-size: 11px;
                font-family: $ibmplexRegular;
                color: #838383;
              }
              p{
                font-size: 12px;
                font-family: $ibmplexRegular;
                color: $black;
              }
              span{
                font-size: 12px;
                color: #000000;
                padding: 3px 15px;
                border: 1px solid #ccc;
                display: inline-block;
                border-radius: 20px;
                margin: 3px 0;
                margin-left: 3px;
                &:first-child{
                  margin-left: 0;
                }
              }
          }
      }
    }
    .finding-third-wrapper {
      position: relative;
      .indicator-block {
          position: absolute;
          right: 15px;
          top: 15px;
          ul {
              li {
                  font-family: $ibmplexSemiBold;
                  font-size: 12px;
                  color: #ffffff;
                  line-height: 23px;
                  span {
                      height: 5px;
                      width: 10px;
                      border-radius: 4px;
                      display: inline-block;
                      margin-right: 5px;
                      position: relative;
                      top: -2px;
                      &.front {
                          background-color: #babc25;
                      }
                      &.mid {
                          background-color: #59a7e4;
                      }
                      &.aft {
                          background-color: #ff9b22;
                      }
                      &.engines {
                          background-color: #a040e4;
                      }
                      &.wings {
                          background-color: #ea6793;
                      }
                  }
              }
            }
          }
        }
      }
      .slick-list{
        width: 100%;
        .slick-track{
          display: flex;
          display: -webkit-flex;
          flex-flow: wrap;
          -webkit-flex-flow: wrap;
          &:before,&:after{
            width: 0;
          }
          .slick-slide{
            height: auto;
            display: flex;
            display: -webkit-flex;
            flex-flow: wrap;
            -webkit-flex-flow: wrap;
            &:before,&:after{
              width: 0;
            }
            >div{
              display: flex;
              display: -webkit-flex;
              flex-flow: wrap;
              -webkit-flex-flow: wrap;
              &:before,&:after{
                width: 0;
              }
            }
          }
        }
      }
    }
    .projects-location{
      box-shadow: 0 2px 4px rgba(0,0,0,0.5);
      h4{
        font-size: 14px;
        padding: 10px;
        font-family: $ibmplexRegular;
        color: #BD10E0;
        text-transform: uppercase;
      }
    }
    .dashboard-override{
        .MuiFormControl-marginNormal{
            margin-bottom: 0;
        }
        .MuiOutlinedInput-input{
            padding: 14px !important;
            @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400);
            &.Mui-disabled{
              cursor: not-allowed;
            }
        }
        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
            padding: 8px 60px 8px 4px !important;
        }
        
        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
            padding: 2px!important;
        }
        .MuiTextField-root {
            label{
                @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
            }
        }
      }
  }
}

