@import '/setting';

body {
  
  .project-forms-section-v {
    margin-top: 58px !important;
    margin-left: 40px !important;

    .form-wrapper-head {
      padding: 5px 15px 5px 10px;
      background: #fff;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
      position: fixed;
      top: 45px;
      left: 70px;
      right: 0;
      z-index: 11;

      .project-detail {
        border-right: 1px solid #d7d7d7;
        padding-right: 10px;

        .project-percentage {
          // float: left;
          padding: 0 5px 0 0;
        }

        .project-card {
          h3 {
            margin-bottom: 3px;

            span {
              @include elementStyles($size: 16px, $color: $black, $fontFamily: $ibmplexBold);
              cursor: pointer;
            }

            text-decoration: underline;
            line-height: 18px;
          }

          p {
            .project-status {
              margin-right: 5px;
              display: inline-block;

              .cancelled {
                background: #F5C7CA;
                color: #5e2135;
                padding: 3px 6px;
                border-radius: 4px;
              }

              .draft {
                background: #EBD186;
                color: #7F5E0D;
                border-radius: 4px;
                padding: 3px 6px;
                border-radius: 4px;
              }

              .initiated {
                background: #acacac;
                color: #000000;
                padding: 3px 6px;
                border-radius: 4px;
              }

              .launched {
                background: #7b8bff;
                color: #fff;
                padding: 3px 6px;
                border-radius: 4px;
              }

              .in-review {
                background: #93ec0d;
                color: #ffffff;
                padding: 3px 6px;
                border-radius: 4px;
              }

              .completed {
                background: #93c49f;
                color: #264D1C;
                padding: 3px 6px;
                border-radius: 4px;
              }

              .approved {
                background: #63eac9;
                color: #264D1C;
                padding: 3px 6px;
                border-radius: 4px;
              }

              .archived {
                background: #9a9991;
                color: #264D1C;
                padding: 3px 6px;
                border-radius: 4px;
              }
            }

            .project-dates {
              @include elementStyles($size: 9px, $color: $black, $fontFamily: $ibmplexMedium);
              opacity: .9;
            }
          }
        }
      }

      .asset-form-card {
        h4 {
          @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium);
        }

        p {
          @include elementStyles($size: 13px, $color: $black , $fontFamily: $ibmplexMedium);
        }

        span {
          text-decoration: underline;
          @include elementStyles($size: 13px, $color: #4050b5, $fontFamily: $ibmplexMedium);
        }
      }

      .form-action-btn-grp {
        float: right;
      }
    }

    .technical-aircraft-froms-v {
      overflow: hidden;
      margin:-15px;

      .t008-form-v {
        background-color: #144067;
        // min-height: 75vh;
        margin: -3px;
        position: relative;
        

        .edit-config {
          padding: 32px 10px 6px 20px ;
          width: 100%;
          text-align: right;
          top: -49px;
          right: -25px;

          a {
            padding: 8px 12px;
            display: inline-block;
            // margin: 0 15px 0;
            color: rgba(255, 255, 255, 0.9);
            font-size: 14px;
          }

          .action-top-bar {
            font-family: $ibmplexRegular;
            font-size: 14px;
            color: #FFFFFF;

            a {
              img {
                margin-right: 6px;
                display: inline-block;
              }
            }
          }

          .toggle-btn-list {
            li {
              font-family: $ibmplexRegular;
              font-size: 14px;
              color: #FFFFFF;
            }
          }

        }

        .seats-container {
          // min-height: 100vh;
          width: 100%;
          overflow-x: scroll;
          margin-top: 5px;

          .seat-matrix {
            padding: 0 20px;
            display: none;
            //display: flex;
            align-items: center;

            .background-image-block {
              background-repeat: no-repeat;
              flex-shrink: 0;
              position: relative;


              width: 605px;
              height: 640px;
              background-position: -11px -2px;
              background-size: cover;
              position: relative;
              top: -23px;

              .cockpit-seats,
              .attended-seats {
                li {
                  position: absolute;
                  transform: translate(-50%, -50%);

                  .disabled-seat {
                    opacity: 0.2 !important;
                    // pointer-events: none;
                  }

                  .current {
                    background-color: #488cc8;
                    color: #fff;
                  }

                  .finding-active {
                    background-color: #488cc8;
                    color: #fff;
                  }

                  .has-finding {
                    background-color: #ff6c6c;
                  }


                  &.fo-seat {
                    position: absolute;
                    right: 28%;
                    top: 25%;
                  }

                  &.cls-seat {
                    position: absolute;
                    right: 44%;
                    top: 37%;
                  }

                  &.o2-seat {
                    position: absolute;
                    right: 44%;
                    bottom: 34%;
                  }

                  &.o1-seat {
                    position: absolute;
                    right: 28%;
                    bottom: 25%;
                  }

                  //&.assitant-seat{
                  //  position: absolute;
                  //  right:10%;
                  //  bottom: 25%;
                  //}
                  a {
                    height: 30px;
                    width: 32px;
                    background-color: transparent;
                    margin-bottom: 10px;
                    padding: 6px 8px 5px;
                    border-radius: 4px;
                    display: inline-block;
                    border: 1px solid #144067;
                    color: #144067;
                    font-family: $ibmplexRegular;
                    font-size: 12px;
                    cursor: pointer;
                    text-align: center;

                    &.active {
                      //background-color: #ff6c6c;
                      background-color: #488cc8;
                    }
                  }
                }
              }
            }

            .cabin-seats {
              display: flex;
              align-items: center;
              height: 685px;
              position: relative;
              left: -13px;
              flex-shrink: 0;
              padding-right: 20px;

              img {
                &.cabin-image {
                  position: absolute;
                  width: 100%;
                  top: -1px;
                  bottom: 0;
                  height: 638px;
                  left: 12px;
                }
              }

              .seat-list {
                white-space: nowrap;
                margin-left: 20px;

                &.next-seat-block {
                  margin-right: 40px;
                }

                &:first-child {
                  margin-left: 200px;
                }

                li {
                  ul {
                    margin-left: 0;
                    padding-top: 0;
                    white-space: nowrap;
                    overflow: hidden;

                    li {
                      a {
                        position: relative;
                        height: 30px;
                        width: 40px;
                        background-color: transparent;
                        margin-bottom: 10px;
                        padding: 6px 8px 5px;
                        border-radius: 4px;
                        display: inline-block;
                        border: 1px solid #144067;
                        color: #144067;
                        font-family: $ibmplexRegular;
                        font-size: 12px;
                        text-align: center;
                        text-transform: uppercase;

                        &.current {
                          background-color: #488cc8;
                          color: #fff;
                        }

                        &.finding-active {
                          background-color: #488cc8;
                          color: #fff;
                        }

                        &.has-finding {
                          background-color: #ff6c6c;
                        }

                        &.disabled-seat {
                          opacity: 0.2 !important;
                          // pointer-events: none;
                        }

                        &.window {
                          // opacity: .2;
                          background-position: center;
                          background-size: cover;
                          border: 1px solid rgba(20, 64, 103, 1);
                          background-image: url("https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/window_inactive.png");
                        }

                        &.window-selected {
                          background-image: url("https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/window_active.png");
                          border: 1px solid rgba(20, 64, 103, 1);
                        }
                      }

                      &:first-child {
                        a {
                          position: absolute;
                          top: 3%;
                        }
                      }

                      &:last-child {
                        a {
                          position: absolute;
                          bottom: 10%;
                        }
                      }

                      &:first-child,
                      &:last-child {
                        a {
                          height: 15px;
                          transform: translateX(0px);
                          -webkit-transform: translateX(0);
                          -moz-transform: translateX(0);
                        }
                      }

                      &:first-child {
                        margin-bottom: 10px;
                      }

                      &:last-child {
                        margin-top: 10px;

                        a {
                          margin-bottom: 0;
                        }
                      }

                      &.column-seperator {
                        margin-bottom: 25px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}