@import '/setting';
body {
    .asset-tech-specs {
        margin: 52px 0 0 64px;

        .tech-specs-asset-hd {
            padding: 4px 10px;
            h4 {
                @include elementStyles($size: $font15, $fontFamily: $ibmplexMedium, $weight: 500);

                span {
                    font-family: $ibmplexLight;
                    font-weight: 300;
                }
            }

            .right-cta {
                float: right;
            }
        }
        .form-wrapper-head{
            padding: 5px 15px 5px 10px;
            background: #fff;
            box-shadow: 0 2px 2px rgba(0,0,0,0.3);
            position: fixed;
            top: 45px;
            left: 70px;
            right: 0;
            z-index: 11;
            .project-detail{
                border-right: 1px solid #d7d7d7;
                padding-right: 10px;
                .project-percentage{
                    // float: left;
                    padding: 0 5px 0 0;
                }
                .project-card{
                    h3{
                        margin-bottom: 3px;
                        span{
                            @include elementStyles($size: 16px, $color: $black, $fontFamily: $ibmplexBold);
                            cursor: pointer;
                        }
                        text-decoration: underline;
                        line-height: 18px;
                    }
                    p{
                        .project-status{
                          margin-right: 5px;
                          display: inline-block;
                            .cancelled{
                              background: #F5C7CA;
                              color:#5e2135;
                              padding: 3px 6px;
                              border-radius: 4px;
                          }
                          .draft{
                              background: #EBD186;
                              color: #7F5E0D;
                              border-radius: 4px;
                              padding: 3px 6px;
                              border-radius: 4px;
                          }
                          .initiated{
                              background: #acacac;
                              color: #000000;
                              padding: 3px 6px;
                              border-radius: 4px;
                          }
                          .launched{
                              background: #7b8bff;
                              color: #fff;
                              padding: 3px 6px;
                              border-radius: 4px;
                          }
                          .in-review{
                              background: #93ec0d;
                              color: #ffffff;
                              padding: 3px 6px;
                              border-radius: 4px;
                          }
                          .completed{
                              background: #93c49f;
                              color: #264D1C;
                              padding: 3px 6px;
                              border-radius: 4px;
                          }
                          .approved{
                              background: #63eac9;
                              color: #264D1C;
                              padding: 3px 6px;
                              border-radius: 4px;
                          }
                          .archived{
                              background: #9a9991;
                              color: #264D1C;
                              padding: 3px 6px;
                              border-radius: 4px;
                          }
                        }
                        .project-dates{
                            @include elementStyles($size: 9px, $color: $black, $fontFamily: $ibmplexMedium);
                            opacity: .9;
                        }
                    }
                }
            }
            .asset-form-card{
                h4{
                    @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium);
                }
                p{
                  @include elementStyles($size: 13px, $color: $black , $fontFamily: $ibmplexMedium);
              } 
                span{
                  text-decoration: underline;
                    @include elementStyles($size: 13px, $color: #4050b5 , $fontFamily: $ibmplexMedium);
                }
            }
            .form-action-btn-grp{
                float:right;
            }
        }
        .edit-basic-info {
            position: absolute;
            top: -10px;
            right: 0px;
            z-index: 10;
            height: 30px;
            text-align: right;  
            cursor: pointer;
            color: #fff;
            background: #fff;
            left: 0;
            width: 100%;
            padding-right: 4px;
            padding-top: 4px;
        }

        .tech-specs-card {
            position: relative;
            top:20px;

            .tech-specs-nav {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 180px;
                    height: 100%;
                    border-right: 1px solid #d7d7d7;
                    // padding-top: 30px;
                    background: #f1f1f1;
            
                    .tech-nav-link {
                        border-bottom: 1px solid #d7d7d7;
                        @include elementStyles($size: $font13, $color: #000, $fontFamily: $ibmplexMedium, $weight: 500);
                        display: block;
                        padding: 6px 10px;
            
                        &.active {
                            color: #5b5bff;
                            background: #fff;
                            position: relative;
                            right: -1px;
                        }
                    }
                }
            .tech-specs-content{
                margin: 0px 0px 0px 180px;
                padding-top: 0px;
                .aircraft-weight-table{
                    table{
                        thead{
                            tr{
                                background: #B5BEDB;
                                &:hover{
                                    background: #B5BEDB;
                                }
                                .header-cell-border{
                                    border-right: 1px solid #d7d7d7;
                                    padding: 5px;
                                    p{
                                        text-align: center;
                                        border-bottom: 1px solid #d7d7d7;
                                    }
                                    .inner-table-hd{
                                        th{
                                            padding: 0 4px;
                                            border: none !important;
                                        }
                                    }
                                }
                            }
                        }
                        tbody{
                            tr{
                                background: #fff !important;
                                .content-cell{
                                    border-right: 1px solid #d7d7d7;
                                    padding: 5px;
                                    .inner-table{
                                        td{
                                            border: none;
                                            padding: 0 4px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .chip-sub-asmbly{
                    padding: 2px 5px;
                    border-radius: 4px !important;
                    font-size: 13px;
                    background: #dddddd;
                    width: max-content;
                }
                .link-cls{
                    color: #007bff;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
    .status-1 {
        background: linear-gradient(90deg, #beed87, #d2e9b6 95%);
        color: #5ec264 !important;
    }

    .status-2 {
        background: linear-gradient(269deg, #ffdfcb, #ffb98d 95%);
        color: #ee7c26 !important;
    }

    .status-3 {
        background: linear-gradient(90deg, #a7c4ff, #729cf1 95%);
        color: #4c79d5 !important;
    }

    .status-4 {
        background: linear-gradient(180deg, #d7d7d7, #f6f6f6 95%, #fff);
        color: #333 !important;
    }

    .status-5 {
        background: linear-gradient(269deg, #e1e1e1, #b3b3b3);
        color: #2c2c2c !important;
    }
    .records-movement-modal{
        .folder-tree{
            padding: 15px;
            .folder-list{
                // overflow: auto;
                // height: 380px;
            }
            .folder-list-dest{
                // overflow: auto;
                // height: 335px;
            }
            .no-folders-cls{
                text-align: center;
                padding: 10px;
            }
            .add-new-folder{
                color: #007bff;
                text-align: right;
                width: 100%;
                padding: 10px;
                border-left: 1px solid #c9c9c9;
                border-right: 1px solid #c9c9c9;
                border-bottom: 1px solid #c9c9c9;
                .add-new-folder-btn{     
                    cursor: pointer;
                }
                .create-folder-action-btn{
                    margin: 10px 0px 0px 10px;
                }
            }
            .parent-fldr-lnk{
                color: #3746a1;
            }
        }
        
        .selected-movement-data{
            margin-top: 20px;
            padding:10px 15px;
            h4{
                @include elementStyles($size: $font15, $color: #3f51b5, $fontFamily: $ibmplexMedium, $weight: 500);
                margin-bottom: 20px;
            }
            .source-selected, .destination-selected{
                border-top: 1px solid #b4b4b4;
                h6{
                    @include elementStyles($size: $font15, $color: #000, $fontFamily: $ibmplexMedium, $weight: 500);
                }
                .folder-naming{
                    padding: 3px 5px;
                    border-bottom: 1px solid #d7d7d7;
                    &:last-child{
                        border-bottom: none;
                    }
                    p{
                        display: flex;
                        padding: 3px 5px;
                        border-bottom: 1px solid #d7d7d7;
                        &:last-child{
                            border-bottom: none;
                        }
                        span{
                            @include elementStyles($size: $font13, $color: #000, $fontFamily: $ibmplexRegular, $weight: 400);
                        }
                        svg{
                            margin-left: auto;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.dropzone {
    min-height: 300px;
    width: 100%;
    border: 2px dashed #aaaaaa;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    cursor: pointer;
}

.dropzone:hover {
    background-color: #e0e0e0;
}
.console-forms-fields{
    input[type="text"], textarea, input[type="number"]{
        height: auto !important;
    }
    .MuiInputBase-multiline{
        padding: 5px !important;
    }
    .MuiFormControl-marginNormal{
        margin-bottom: 0;
    }
    .MuiOutlinedInput-input{
        padding: 14px !important;
        @include elementStyles($size: 15px, $color: $black, $fontFamily: $ibmplexRegular, $weight:400)        
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
        padding: 0;
    }
    .MuiTextField-root {
        label{
            @include elementStyles($size: 14px, $color: $black, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
}
.drop_zone{
    margin-top: 20px ;
    &.small-blk{
        min-height: 275px !important;
        padding: 15px;
        width: 100%;
        p{
        font-size: $font15;
        margin: 0 0 10px 0;
        }
    }
}
.project-modal {
    .MuiOutlinedInput-input {
        padding: 8px !important;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 2px !important;
    }
    ::placeholder {
        opacity: 1 !important;
    }
    .MuiInputLabel {
              left: inherit;
              right: 1.75rem;
              transform-origin: right;
         }
}
